import { CreateControllerFn } from 'yoshi-flow-editor-runtime';
import { createEventHandler } from 'yoshi-flow-editor-runtime/tpa-settings';
import { createPlansApi } from '../../services';
import { Analytics } from '../../services/analytics';
import { PricingPlansBi } from '../../services/bi';
import { CommonBIDataFromFlowAPI } from '../../services/CommonBIDataFromFlowAPI';
import { apiHeaders } from '../../services/headers';
import { IEvents, TabState } from '../../types/common';
import { PlanListInteractions } from '../../types/PlanListFedops';
import { PlanListController } from './PlanListController';

const createController: CreateControllerFn = async (params) => {
  const {
    flowAPI,
    controllerConfig: { setProps, wixCodeApi, appParams, config },
  } = params;

  // const baseUrl = wixCodeApi.location.baseUrl.split('/').slice(0, 3).join('/');
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const baseUrl = isSSR ? 'https://www.wix.com' : '';
  const headers = apiHeaders({ Authorization: appParams.instance });
  const api = createPlansApi(headers, baseUrl);

  const componentEventHandler = createEventHandler<IEvents>(config.publicData.COMPONENT || {});

  const bi = new PricingPlansBi(flowAPI.biLogger!, new CommonBIDataFromFlowAPI(flowAPI));

  const analytics = new Analytics(wixCodeApi.window);
  const pp = new PlanListController(setProps, api, flowAPI, wixCodeApi, appParams, bi, analytics);

  return {
    async pageReady() {
      flowAPI.fedopsLogger.interactionStarted(PlanListInteractions.PageReady);

      componentEventHandler.on('tabState', (tabState: TabState) => pp.setProps({ tabState }));
      componentEventHandler.onReset(() => pp.setProps({ tabState: TabState.REGULAR }));

      wixCodeApi.site.onInstanceChanged((e) => (headers.Authorization = e.instance), appParams.appDefinitionId);

      flowAPI.fedopsLogger.interactionEnded(PlanListInteractions.PageReady);
      await pp.initialize();
    },
    updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
    },
  };
};

export default createController;
